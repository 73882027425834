<template>
	<section class="content">
		<div class="card">
			<div class="card-body">
				<div class="row mb-3 justify-content-end" style="row-gap: .5rem;">
					<div class="col-auto mr-auto mr-sm-0">
						<label class="control-label my-2 ml-2 font-weight-normal">Bergabung :</label>
					</div>
					<div class="col-auto mr-auto" :class="{ 'mr-sm-0' : false }">
						<div class="input-group">
							<div class="input-group-prepend">
								<div class="input-group-text rounded-left d-flex align-items-center pr-1 bg-white">
									<i class="fi fi-rr-calendar d-flex"></i>
								</div>
							</div>
							<input type="text" ref="daterange" class="form-control border-left-0 border-right-0" />
							<div class="input-group-append">
								<div class="input-group-text rounded-right d-flex align-items-center border-left-0 pl-1 bg-white">
									<i class="fi fi-rr-angle-right d-flex"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-auto mr-auto" v-if="false">
						<div class="input-group cursor-pointer" @click="selectFilter">
							<span class="form-control border-right-0">Filter</span>
							<div class="input-group-append">
								<div class="input-group-text rounded-right d-flex align-items-center border-left-0 pl-1 bg-white">
									<i class="fi fi-rr-filter d-flex"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-auto">
						<div class="btn-group" ref="toolbar">
							<button type="button" data-action="read" class="btn btn-outline-primary border-right-0">
								<i class="fi fi-rr-refresh d-flex"></i>
							</button>
							<button type="button" :disabled="loading" v-on:click="downloadXlsx" data-action="export-xlsx" class="btn btn-outline-primary">
								<i class="fi fi-rr-file-download"></i> Download
							</button>
						</div>
					</div>
					<div class="col-auto" v-if="false">
						<button type="button" :disabled="loading" v-on:click="showForm" data-action="export-xlsx" class="btn btn-primary">
							<i class="fi fi-rr-plus"></i> Tambah Shipper
						</button>
					</div>
				</div>

				<div class="row mb-3" style="row-gap: 15px;" v-if="filter_list.length > 0">
					<div class="col-auto" v-for="data in filter_list">
						<span class="btn border border-secondary rounded-pill pr-2 text-secondary">
							{{ data.label }} <span class="cursor-pointer" @click="removeFilter(data.key)">&#128473;</span>
						</span>
					</div>
					<div class="col-auto" v-if="filter_list.length > 1">
						<span class="btn btn-link px-0 text-secondary cursor-pointer font-weight-bold" @click="clearFilter">
							Hapus Semua
						</span>
					</div>
				</div>

				<table class="table table-hover row-border" ref="tableshipper" id="tableshipper">
					<thead>
						<tr>
							<th>Shipper ID</th>
							<th>NIK</th>
							<th>Nama Shipper</th>
							<th>Email Shipper</th>
							<th>No. HP</th>
							<th>Tanggal Join</th>
							<th>Terakhir Login</th>
							<th>Type</th>
							<th>Status</th>
							<th>CS/Sales</th>
							<th>Akses Kurir</th>
							<th>Rekening</th>
							<!-- <th>Perubahan Data Terakhir</th> -->
							<th>Aksi</th>
						</tr>
					</thead>
					<tbody @click="handleClick">
					</tbody>
				</table>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="filterDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form @submit="getFilter($event)">
						<div class="modal-header">
							<h5 class="modal-title font-weight-bold">Filter</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="form-group m-2 p-3 bg-light rounded">
								<div class="row">
									<div class="col d-flex justify-content-between">
										<label class="h6">Perubahan Data</label>
									</div>
								</div>
								<div class="grid grid-auto-flow-column" style="--bs-rows: 4;--bs-columns: 2;">
									<div v-for="data in allShipperChange">
										<div class="icheck-material-orange icheck-inline form-check w-100">
											<input class="form-check-input" type="checkbox" :id="data.key" :value="data.key" v-model="filter.shipper_change" />
											<label class="form-check-label w-100" :for="data.key">{{ data.label }}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-primary">Cari</button>
							<button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, formatCurrency,openChat } from "@/libs/hxcore";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Swal from "sweetalert2";
import "icheck-material";

export default {
	name: "Shipper",
	data() {
		return {
			dt1: moment().startOf("month"),
			dt2: moment(),
			allShipperChange: [
				{
					key: 'password',
					label: 'Kata Sandi'
				},
				{
					key: 'email',
					label: 'Email'
				},
				{
					key: 'status',
					label: 'Status'
				},
				{
					key: 'rekening',
					label: 'Rekening'
				},
				{
					key: 'phone',
					label: 'Nomor HP'
				},
				{
					key: 'address',
					label: 'Alamat'
				},
				{
					key: 'marking',
					label: 'Tanda'
				},
				{
					key: 'courier',
					label: 'Kurir'
				}
			],
			loading: false,
			errors: [],
			filter_list: [],
			listBank: [],
			shipmentAmount: [
				{
					id: '5',
					label: '1 - 5'
				},
				{
					id: '30',
					label: '6 - 30'
				},
				{
					id: '100',
					label: '31 - 100'
				},
				{
					id: '1000',
					label: ' > 100'
				}
			],
			listMember: [],
			listCS: [],
			listMarking: [
				{
					id: '',
					label: '',
					hidden: false
				},
				{
					id: 'blacklist',
					label: 'Blacklist',
					hidden: false
				},
				{
					id: 'suspect',
					label: 'Suspect',
					hidden: false
				},
				{
					id: 'trust',
					label: 'Trust',
					hidden: false
				},
				{
					id: 'suspect rekening',
					label: 'Auto suspect rekening',
					hidden: true
				}
			],
			listCourier: [],
			method: "",
			roles: "",
			filter: {
				shipper_change: [],
				dt1: moment().startOf("month"),
				dt2: moment()
			},
			name: "",
			value: "",
			additionals: [{
				name: "",
				value: "",
			}]
		};
	},
	computed: {},
	components: {
		vSelect,
	},
	watch:{},
	created: function () {
		this.roles = this.$route.meta.roles;
		this.userInfo = auth.user();

		if(this.$route.params.filter) {
			this.filter = this.$route.params.filter;
		}

		if(this.filter.dt1 && this.filter.dt2)
		{
			this.dt1 = moment(this.filter.dt1);
			this.dt2 = moment(this.filter.dt2);
		}
		this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');
		if(this.filter.gid)
		{
			this.groupId = this.filter.gid;
			this.dateStart = '';
			this.dateEnd = '';
		}
		if(Object.keys(this.filter).length<1)
		{
			if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
		}
		else{
			sessionStorage.filterData = JSON.stringify(this.filter);
			sessionStorage.filterStatus = 1;
		}
		this.filter.datatype = this.data_type;

		authFetch("/shipper/member_type")
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				this.listMember = js.data;
			});

		authFetch("/shipper/bank")
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				this.listBank = js.data;
			});

			authFetch("/shipper/kurir")
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				this.listCourier = js.data;
			});
			this.loadCS();
	},
	methods: {
		loadCS: function () {
			this.roles = this.$route.meta.roles;
			this.userInfo = auth.user();
			this.filter.datatype = this.data_type;
			authFetch("/shipper/usercs")
				.then((res) => {
						if (res.status === 201) {} else if (res.status === 400) {}
						return res.json();
				})
				.then((js) => {
						this.listCS = js.data;
				});
		},
		getKurirLogo(kurir) {
			const fileName = kurir.toLowerCase();

			return require(`../assets/img/${fileName}.png`); // the module request
		},
		handleClick(e) {
			if (e.target.matches(".link-role")) {
				this.$router.push({ path: "/permit/" + e.target.dataset.id });
				return false;
			}
			if (e.target.matches(".link-order")) {
				let route = this.$router.resolve({
					path: "/shipper/detail/" + e.target.dataset.id
				});
				window.open(route.href,"_blank");
				// return false;
			}
			if (e.target.matches(".shipper-detail")) {
				let route = this.$router.resolve({
					path: "/shipper/detail/" + e.target.dataset.id
				});
				window.open(route.href,"_blank");
				// return false;
			}
			if (e.target.matches(".shipper-delete")) {
				authFetch("/shipper/" + e.target.dataset.id``, {
					method: "DELETE",
					body: "id=" + e.target.dataset.id``,
				})
				.then((res) => {
					return res.json();
				})
				.then((js) => {
					this.table.api().ajax.reload();
				});
			}
			if (e.target.matches(".shipper-chat")) {
				openChat(e.target.dataset.to);
				return false;
			}
		},
		selectFilter() {
			$(this.$refs.filterDialog).modal("toggle");
		},
		getFilter(event) {
			this.filter_list = [];
			for (const key of this.filter.shipper_change) {
				this.filter_list.push(this.allShipperChange.find((e) => e.key == key));
			}
			this.table.api().ajax.reload();
			$(this.$refs.filterDialog).modal("toggle");
			event.preventDefault();
		},
		removeFilter(value) {
			this.filter_list = this.filter_list.filter((e) => e.key !== value);
			this.filter.shipper_change = this.filter.shipper_change.filter((e) => e !== value);
			this.table.api().ajax.reload();
		},
		clearFilter() {
			this.filter_list = [];
			this.filter.shipper_change = [];
			this.clearStatus();
		},
		dataType(event) {
			this.data_type = event.target.value;
			this.filter.datatype = this.data_type;
			// this.table.api().ajax.reload();
		},
		onDate() {
			// var params = { dt1: this.dateStart, dt2: this.dateEnd };
			// var search = new URLSearchParams(params);
			this.table.api().ajax.reload();
		},
		downloadXlsx: function (e) {
			this.loading = true;
			var table = $('#tableshipper').DataTable();
			var length = table.page.info().recordsTotal;

			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal data AWB sebanyak kurang lebih 20.000 data.`, "error");
				this.loading = false;
				return false;
			}

			var filter = JSON.parse(JSON.stringify(this.filter));
				filter.q = $("input[name=q]").val();
				filter.category = $("select[name=category]").val();

			console.log(filter);
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
			console.log(data);
			this.loadingContent = true;
			authFetch("/report/shipper/excell", {
				method: "POST",
				body: data,
			})
			.then((response) => {
				if (response.status === 201) {
				} else if (response.status === 400) {
				} else if (response.status === 500){
					Swal.fire("Timeout!", ``, "error");
					this.loading2 = false;
					this.msg = '';
					return false;
				}

				return response.blob();
			})
			.then((blob) => {
				setTimeout(() => {
					this.loading = false;
				}, 1000);
				this.loadingContent = false;
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = "Shipper.xlsx";
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			});
		}
	},
	mounted() {
		const e = this.$refs;
		var self = this;
		new Daterangepicker(
			this.$refs.daterange,
			{
				startDate: !this.dt1 ? moment().day(-31) : this.dt1,
				endDate: !this.dt2 ? moment() : this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				self.data_type = self.data_type;
				self.filter.datatype = self.data_type;
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.onDate();
			}
		);
		//console.log('roles', this.$route.params.roles);
		this.table = createTable(e.tableshipper, {
			title: "",
			roles: this.$route.params.roles,
			ajax: "/shipper",
			frame: false,
			toolbarEl: e.toolbar,
			fixedColumns: {
				start: 1,
				end: 1
			},
			scrollCollapse: true,
			scrollX: true,
			processing: true,
			autoWidth:false,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
			},
			order: [[5,'desc']],
			paramData: function (d) {
				var filter = JSON.parse(JSON.stringify(self.filter));
				//d = filter;
				//d.dev = filter.delivered;
				d.dt1 = self.dateStart;
				d.dt2 = self.dateEnd;
				d.datatype = self.data_type;
				var filter = JSON.parse(JSON.stringify(self.filter));
				for (const key in filter) {
						d[key] = filter[key] === false ? 0 : filter[key];
				}
			},
			columns: [
				{ 
					data: "shipper_code",
					render: function (data, type, row, meta) {
						return data + '<div class="badge badge-' + (row.shipper_type == 1 ? 'agen' : 'cod') + '">bos' + (row.shipper_type == 1 ? 'Agen' : 'COD') + '</div>';
					},
				},
				{ data: "ktp_nik" },
				{
					data: "display_name",
					render: function (data, type, row, meta) {
						return (`<span class="link link-order text-info" style="cursor:pointer;" data-id="` + row.user_id +'">'+ data +`</span>`);
					},
				},
				{ data: "email" },
				{
					data: "kontak_hp",
					render: function (data, type, row, meta) {
						if (!data) {
							return '';
						} else {
							return `<a class="link shipper-chat text-info" style="cursor:pointer;" data-to="${data}">${data}</a>`;
						}
					},
				},
				{
					data: "created_on",
					render: function (data, type, row, meta) {
						return !data ? '-' : moment(data).format("DD/MM/YYYY HH:mm");
					}
				},
				{
					data: "last_login",
					render: function (data, type, row, meta) {
						return !data ? '-' : moment(data).format("DD/MM/YYYY HH:mm");
					},
				},
				{ data: "title" },
				{
					data: "active",
					render: function (data, type, row, meta) {
						var status = null;

						if (data > 0) {
							status = '<span class="badge badge-success">Active</span>'
						} else {
							status = '<span class="badge badge-danger">Not Active</span>'
						}

						if (row.ktp_verify == 1) {
							status += '<span class="badge badge-success">Verified KTP</span>'
						}

						if (row.drop_off == true) {
							status += '<span class="badge badge-success">Drop Off</span>'
						}

						if (row.is_valid_hp == "t") {
							status += '<span class="badge badge-success">Verified WA</span>'
						}

						if (row.marking) {
							status += '<span class="badge badge-warning text-capitalize">' + row.marking + '</span>'
						}

						return '<div class="d-flex flex-wrap" style="gap: 0.3rem;">' + status + '</div>';
					}
				},
				{ data: "usercs" },
				{
					data: "kurir",
					sortable: false,
					render: function (data, type, row, meta) {
						let act = ``;
						for (var x in data) {
							var kurir = data[x];
							act += `<span class="badge badge-info">${kurir}</span>`;
						}
						return '<div class="d-flex flex-wrap" style="gap: 0.3rem;">' + act + '</div>';
					},
				},
				{
					data: "rekening",
					sortable: false,
					render: function (data, type, row, meta) {
						var rekening = '';

						if (data['bank_rekening'] !== undefined ) {
							rekening= `${data['bank_atasnama']}<br>${data['bank_rekening']}`;
						}

						return rekening;
					}
				},
				{
					data: null,
					render: function (data, type, row, meta) {
						return `<div class="btn-group" style="gap: 16px;">
									<div class="btn btn-link btn-lg p-0 text-warning"><i class="fi fi-rr-edit cursor-pointer shipper-detail" data-id="` + row.user_id + `"></i></div>
									<div class="btn btn-link btn-lg p-0 text-danger d-none"><i class="fi fi-rr-trash cursor-pointer shipper-delete" data-id="` + row.user_id + `"></i></div>
								</div>`;
					}
				}
			],
			filterBy: [0, 1, 2, 3, 4, 11],
			rowCallback: function (row, data) {
			},
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
		});
	},
};
</script>
<style type="text/css">
:root {
	--bs-gap: 0;
}

#tableshipper_wrapper .col-md-6:nth-child(1) {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}

#tableshipper_wrapper .col-md-6:nth-child(2) {
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

@media screen and (max-width: 767px) {
	#tableshipper_length {
		text-align: left;
	}
}

.dataTables_filter {
	padding-right: 0;
}

.table-bordered th, .table-bordered td {
	border-left: 0;
	border-right: 0;
}

.undefined_caption {
	visibility: hidden;
}
</style>